// this is an auto generated file. This will be overwritten

export const createPresupuesto = /* GraphQL */ `
  mutation CreatePresupuesto($CreatePresupuestoInput: CreatePresupuestoInput!) {
    createPresupuesto(CreatePresupuestoInput: $CreatePresupuestoInput) {
      or_idordenExt
      cte_Nombre
      cte_Apellidos
      or_ordenOrigen
      or_codMon
      or_totalFinal
      createdAt
      updatedAt
      delet
    }
  }
`;
export const createAplicaciones = /* GraphQL */ `
  mutation CreateAplicaciones(
    $createAplicacionesInput: CreateAplicacionesInput!
  ) {
    createAplicaciones(createAplicacionesInput: $createAplicacionesInput) {
      delet
      descripcion
      id
      logo
      nombreApp
    }
  }
`;
export const createClientes = /* GraphQL */ `
  mutation CreateClientes($createClientesInput: CreateClientesInput!) {
    createClientes(createClientesInput: $createClientesInput) {
      apellido
      correo
      delet
      id
      nombre
      telefono
      uid
    }
  }
`;
export const createEmpresa = /* GraphQL */ `
  mutation CreateEmpresa($createEmpresaInput: CreateEmpresaInput!) {
    createEmpresa(createEmpresaInput: $createEmpresaInput) {
      calle
      codigoPostal
      colonia
      correoElectronico
      delet
      estado
      estatus
      id
      idCliente
      localidad
      logo
      municipio
      noExt
      noInt
      nombreComercial
      pais
      regimenFiscal
      rfc
      telefono
      tipoPersona
    }
  }
`;
export const createEmpresaAplicacionUsuario = /* GraphQL */ `
  mutation CreateEmpresaAplicacionUsuario(
    $createEmpresaAplicacionUsuarioInput: CreateEmpresaAplicacionUsuarioInput!
  ) {
    createEmpresaAplicacionUsuario(
      createEmpresaAplicacionUsuarioInput: $createEmpresaAplicacionUsuarioInput
    ) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const createPaquete = /* GraphQL */ `
  mutation CreatePaquete($createPaqueteInput: CreatePaqueteInput!) {
    createPaquete(createPaqueteInput: $createPaqueteInput) {
      delet
      id
      idAplicacion
      idPaquete
    }
  }
`;
export const createPlanes = /* GraphQL */ `
  mutation CreatePlanes($createPlanesInput: CreatePlanesInput!) {
    createPlanes(createPlanesInput: $createPlanesInput) {
      costoAnual
      costoMensual
      delet
      descripcion
      fechaFin
      id
      nombrePaquete
    }
  }
`;
export const createRenovaciones = /* GraphQL */ `
  mutation CreateRenovaciones(
    $createRenovacionesInput: CreateRenovacionesInput!
  ) {
    createRenovaciones(createRenovacionesInput: $createRenovacionesInput) {
      delet
      dias
      fecha
      fechaRenovacion
      id
      idApp
      idCliente
      idEmpresa
      idTransaccion
      idUsuario
    }
  }
`;
export const createTransacciones = /* GraphQL */ `
  mutation CreateTransacciones(
    $createTransaccionesInput: CreateTransaccionesInput!
  ) {
    createTransacciones(createTransaccionesInput: $createTransaccionesInput) {
      delet
      fecha
      id
      idEmpresa
      idPlan
      idProcesador
      monto
      numUsuarios
    }
  }
`;
export const createUsuarios = /* GraphQL */ `
  mutation CreateUsuarios($createUsuariosInput: CreateUsuariosInput!) {
    createUsuarios(createUsuariosInput: $createUsuariosInput) {
      apellido
      correo
      delet
      id
      idCliente
      nombre
      telefono
      uid
    }
  }
`;
export const createcat_prodservsat = /* GraphQL */ `
  mutation Createcat_prodservsat(
    $Createcat_prodservsatInput: Createcat_prodservsatInput!
  ) {
    createcat_prodservsat(
      Createcat_prodservsatInput: $Createcat_prodservsatInput
    ) {
      Prod_ClaveProdServ
      Prod_Nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createcat_unidadsat = /* GraphQL */ `
  mutation Createcat_unidadsat(
    $Createcat_unidadsatInput: Createcat_unidadsatInput!
  ) {
    createcat_unidadsat(Createcat_unidadsatInput: $Createcat_unidadsatInput) {
      Unid_ClaveUnidad
      Unid_Descripcion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deletePresupuesto = /* GraphQL */ `
  mutation DeletePresupuesto($or_idorden: Int!) {
    deletePresupuesto(or_idorden: $or_idorden) {
      or_idordenExt
      cte_Nombre
      cte_Apellidos
      or_ordenOrigen
      or_codMon
      or_totalFinal
      createdAt
      updatedAt
      delet
    }
  }
`;
export const deleteAplicaciones = /* GraphQL */ `
  mutation DeleteAplicaciones($id: Int!) {
    deleteAplicaciones(id: $id) {
      delet
      descripcion
      id
      logo
      nombreApp
    }
  }
`;
export const deleteClientes = /* GraphQL */ `
  mutation DeleteClientes($id: Int!) {
    deleteClientes(id: $id) {
      apellido
      correo
      delet
      id
      nombre
      telefono
      uid
    }
  }
`;
export const deleteEmpresa = /* GraphQL */ `
  mutation DeleteEmpresa($id: Int!) {
    deleteEmpresa(id: $id) {
      calle
      codigoPostal
      colonia
      correoElectronico
      delet
      estado
      estatus
      id
      idCliente
      localidad
      logo
      municipio
      noExt
      noInt
      nombreComercial
      pais
      regimenFiscal
      rfc
      telefono
      tipoPersona
    }
  }
`;
export const deleteEmpresaAplicacionUsuario = /* GraphQL */ `
  mutation DeleteEmpresaAplicacionUsuario($id: Int!) {
    deleteEmpresaAplicacionUsuario(id: $id) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const deletePaquete = /* GraphQL */ `
  mutation DeletePaquete($id: Int!) {
    deletePaquete(id: $id) {
      delet
      id
      idAplicacion
      idPaquete
    }
  }
`;
export const deletePlanes = /* GraphQL */ `
  mutation DeletePlanes($id: Int!) {
    deletePlanes(id: $id) {
      costoAnual
      costoMensual
      delet
      descripcion
      fechaFin
      id
      nombrePaquete
    }
  }
`;
export const deleteRenovaciones = /* GraphQL */ `
  mutation DeleteRenovaciones($id: Int!) {
    deleteRenovaciones(id: $id) {
      delet
      dias
      fecha
      fechaRenovacion
      id
      idApp
      idCliente
      idEmpresa
      idTransaccion
      idUsuario
    }
  }
`;
export const deleteTransacciones = /* GraphQL */ `
  mutation DeleteTransacciones($id: Int!) {
    deleteTransacciones(id: $id) {
      delet
      fecha
      id
      idEmpresa
      idPlan
      idProcesador
      monto
      numUsuarios
    }
  }
`;
export const deleteUsuarios = /* GraphQL */ `
  mutation DeleteUsuarios($id: Int!) {
    deleteUsuarios(id: $id) {
      apellido
      correo
      delet
      id
      idCliente
      nombre
      telefono
      uid
    }
  }
`;
export const updatePresupuesto = /* GraphQL */ `
  mutation UpdatePresupuesto($updatePresupuestoInput: UpdatePresupuestoInput!) {
    updatePresupuesto(updatePresupuestoInput: $updatePresupuestoInput) {
      or_idordenExt
      cte_Nombre
      cte_Apellidos
      or_ordenOrigen
      or_codMon
      or_totalFinal
      createdAt
      updatedAt
      delet
    }
  }
`;
export const updateAplicaciones = /* GraphQL */ `
  mutation UpdateAplicaciones(
    $updateAplicacionesInput: UpdateAplicacionesInput!
  ) {
    updateAplicaciones(updateAplicacionesInput: $updateAplicacionesInput) {
      delet
      descripcion
      id
      logo
      nombreApp
    }
  }
`;
export const updateClientes = /* GraphQL */ `
  mutation UpdateClientes($updateClientesInput: UpdateClientesInput!) {
    updateClientes(updateClientesInput: $updateClientesInput) {
      apellido
      correo
      delet
      id
      nombre
      telefono
      uid
    }
  }
`;
export const updateEmpresa = /* GraphQL */ `
  mutation UpdateEmpresa($updateEmpresaInput: UpdateEmpresaInput!) {
    updateEmpresa(updateEmpresaInput: $updateEmpresaInput) {
      calle
      codigoPostal
      colonia
      correoElectronico
      delet
      estado
      estatus
      id
      idCliente
      localidad
      logo
      municipio
      noExt
      noInt
      nombreComercial
      pais
      regimenFiscal
      rfc
      telefono
      tipoPersona
    }
  }
`;
export const updateEmpresaAplicacionUsuario = /* GraphQL */ `
  mutation UpdateEmpresaAplicacionUsuario(
    $updateEmpresaAplicacionUsuarioInput: UpdateEmpresaAplicacionUsuarioInput!
  ) {
    updateEmpresaAplicacionUsuario(
      updateEmpresaAplicacionUsuarioInput: $updateEmpresaAplicacionUsuarioInput
    ) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const updatePaquete = /* GraphQL */ `
  mutation UpdatePaquete($updatePaqueteInput: UpdatePaqueteInput!) {
    updatePaquete(updatePaqueteInput: $updatePaqueteInput) {
      delet
      id
      idAplicacion
      idPaquete
    }
  }
`;
export const updatePlanes = /* GraphQL */ `
  mutation UpdatePlanes($updatePlanesInput: UpdatePlanesInput!) {
    updatePlanes(updatePlanesInput: $updatePlanesInput) {
      costoAnual
      costoMensual
      delet
      descripcion
      fechaFin
      id
      nombrePaquete
    }
  }
`;
export const updateRenovaciones = /* GraphQL */ `
  mutation UpdateRenovaciones(
    $updateRenovacionesInput: UpdateRenovacionesInput!
  ) {
    updateRenovaciones(updateRenovacionesInput: $updateRenovacionesInput) {
      delet
      dias
      fecha
      fechaRenovacion
      id
      idApp
      idCliente
      idEmpresa
      idTransaccion
      idUsuario
    }
  }
`;
export const updateTransacciones = /* GraphQL */ `
  mutation UpdateTransacciones(
    $updateTransaccionesInput: UpdateTransaccionesInput!
  ) {
    updateTransacciones(updateTransaccionesInput: $updateTransaccionesInput) {
      delet
      fecha
      id
      idEmpresa
      idPlan
      idProcesador
      monto
      numUsuarios
    }
  }
`;
export const updateUsuarios = /* GraphQL */ `
  mutation UpdateUsuarios($updateUsuariosInput: UpdateUsuariosInput!) {
    updateUsuarios(updateUsuariosInput: $updateUsuariosInput) {
      apellido
      correo
      delet
      id
      idCliente
      nombre
      telefono
      uid
    }
  }
`;
export const createMv_unidades = /* GraphQL */ `
  mutation CreateMv_unidades($createMv_unidadesInput: CreateMv_unidadesInput!) {
    createMv_unidades(createMv_unidadesInput: $createMv_unidadesInput) {
      unid_cod_unidad
      unid_nombre
      ID
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_tasas = /* GraphQL */ `
  mutation CreateMv_tasas($createMv_tasasInput: CreateMv_tasasInput!) {
    createMv_tasas(createMv_tasasInput: $createMv_tasasInput) {
      ts_Empresa
      ts_codTasa
      ts_slugTasa
      ts_nomTasa
      ts_afStock
      ts_afFin
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_log = /* GraphQL */ `
  mutation CreateMv_log($createMv_logInput: CreateMv_logInput!) {
    createMv_log(createMv_logInput: $createMv_logInput) {
      lg_uid
      lg_nombre
      lg_actividad
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_agentes = /* GraphQL */ `
  mutation CreateMv_agentes($createMv_agentesInput: CreateMv_agentesInput!) {
    createMv_agentes(createMv_agentesInput: $createMv_agentesInput) {
      agen_Apellido
      agen_Puesto
      agen_Uid
      ID
      agen_idEmpresa
      agen_nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_atributos = /* GraphQL */ `
  mutation CreateMv_atributos(
    $createMv_atributosInput: CreateMv_atributosInput!
  ) {
    createMv_atributos(createMv_atributosInput: $createMv_atributosInput) {
      ID
      atr_idatributo
      atr_idempresa
      atr_idw
      atr_nombre
      atr_opcion
      atr_posicion
      atr_variacion
      atr_visible
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_categorias = /* GraphQL */ `
  mutation CreateMv_categorias(
    $createMv_categoriasInput: CreateMv_categoriasInput!
  ) {
    createMv_categorias(createMv_categoriasInput: $createMv_categoriasInput) {
      cat_description
      ID
      cat_idCategoria
      cat_idEmpresa
      cat_image
      cat_links
      cat_name
      cat_parent
      cat_slug
      delet
      createdAt
      updatedAt
      idCategorias
    }
  }
`;
export const createMv_clientedet = /* GraphQL */ `
  mutation CreateMv_clientedet(
    $createMv_clientedetInput: CreateMv_clientedetInput!
  ) {
    createMv_clientedet(createMv_clientedetInput: $createMv_clientedetInput) {
      cd_agente
      cd_categoria
      cd_condPago
      cd_idCLiente
      ID
      cd_idEmpresa
      cd_limCred
      cd_lp
      cd_plaCred
      cd_usoCfdi
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_clientes = /* GraphQL */ `
  mutation CreateMv_clientes($createMv_clientesInput: CreateMv_clientesInput!) {
    createMv_clientes(createMv_clientesInput: $createMv_clientesInput) {
      cte_Apellidos
      cte_Correo
      cte_Nombre
      ID
      cte_idEmpresa
      cte_idwoo
      cte_razonSocial
      cte_rfc
      cte_tel
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_contactos = /* GraphQL */ `
  mutation CreateMv_contactos(
    $createMv_contactosInput: CreateMv_contactosInput!
  ) {
    createMv_contactos(createMv_contactosInput: $createMv_contactosInput) {
      con_Correo
      con_Curp
      con_apellido
      con_fechaNac
      con_idContacto
      con_idEmpresa
      con_idExterna
      con_nombre
      con_puesto
      con_rfc
      con_telefono
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_detallelpv = /* GraphQL */ `
  mutation CreateMv_detallelpv(
    $createMv_detallelpvInput: CreateMv_detallelpvInput!
  ) {
    createMv_detallelpv(createMv_detallelpvInput: $createMv_detallelpvInput) {
      delet
      createdAt
      updatedAt
      lpv_CodProveedor
      ID
      lpv_CodProducto
      lpv_IdTab
      lpv_TipCalc
      lpv_codLp
      lpv_factor
      lpv_idEmpresa
      lpv_prcBase
      lpv_prcFin
    }
  }
`;
export const createMv_direccion = /* GraphQL */ `
  mutation CreateMv_direccion(
    $createMv_direccionInput: CreateMv_direccionInput!
  ) {
    createMv_direccion(createMv_direccionInput: $createMv_direccionInput) {
      delet
      createdAt
      updatedAt
      dir_CP
      dir_Pais
      dir_ciudad
      dir_correo
      dir_dirLine1
      dir_dirLine2
      dir_estado
      dir_idCliente
      ID
      dir_idEmpresa
      dir_tel
      dir_tipoDir
    }
  }
`;
export const createMv_estatus = /* GraphQL */ `
  mutation CreateMv_estatus($createMv_estatusInput: CreateMv_estatusInput!) {
    createMv_estatus(createMv_estatusInput: $createMv_estatusInput) {
      delet
      createdAt
      updatedAt
      ID
      esta_descripcion
      esta_idEstatus
    }
  }
`;
export const createMv_etiquetas = /* GraphQL */ `
  mutation CreateMv_etiquetas(
    $createMv_etiquetasInput: CreateMv_etiquetasInput!
  ) {
    createMv_etiquetas(createMv_etiquetasInput: $createMv_etiquetasInput) {
      delet
      createdAt
      updatedAt
      et_count
      et_description
      et_id
      et_idEmpresa
      et_idEtiqueta
      et_links
      et_name
      et_self
      et_slug
    }
  }
`;
export const createMv_listaprecios = /* GraphQL */ `
  mutation CreateMv_listaprecios(
    $createMv_listapreciosInput: CreateMv_listapreciosInput!
  ) {
    createMv_listaprecios(
      createMv_listapreciosInput: $createMv_listapreciosInput
    ) {
      delet
      createdAt
      updatedAt
      lp_CodMoneda
      ID
      lp_codLp
      lp_nomdLp
    }
  }
`;
export const createMv_ordencontrol = /* GraphQL */ `
  mutation CreateMv_ordencontrol(
    $createMv_ordencontrolInput: CreateMv_ordencontrolInput!
  ) {
    createMv_ordencontrol(
      createMv_ordencontrolInput: $createMv_ordencontrolInput
    ) {
      ctd_NumRequisi
      ctd_OrderUrl
      ctd_clienteUrl
      ctd_codreg
      ID
      ctd_idordenes
      ctd_pedidoespecial
      ctd_precioxvolumen
      ctd_prjavisoentrega
      ctd_prjconfirmarpedido
      ctd_status
      ctd_tiempoentrega
      ctd_tiplib
      ctd_tpcarga
      ctd_valida
      ctd_vigenciacotizacion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_ordenes = /* GraphQL */ `
  mutation CreateMv_ordenes($createMv_ordenesInput: CreateMv_ordenesInput!) {
    createMv_ordenes(createMv_ordenesInput: $createMv_ordenesInput) {
      delet
      createdAt
      updatedAt
      ID
      or_agenteID
      or_clienteIP
      or_codMon
      or_descImp
      or_descTotal
      or_dirEnvioID
      or_dirFactID
      or_envImp
      or_envioTotal
      or_idCLiente
      or_idCondPago
      or_idEmpresa
      or_idListaP
      or_idordenExt
      or_mensaje
      or_ordenOrigen
      or_subtotal
      or_totalFinal
      or_totalImp
    }
  }
`;
export const createMv_ordenesdetalles = /* GraphQL */ `
  mutation CreateMv_ordenesdetalles(
    $createMv_ordenesdetallesInput: CreateMv_ordenesdetallesInput!
  ) {
    createMv_ordenesdetalles(
      createMv_ordenesdetallesInput: $createMv_ordenesdetallesInput
    ) {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const createMv_productos = /* GraphQL */ `
  mutation CreateMv_productos(
    $createMv_productosInput: CreateMv_productosInput!
  ) {
    createMv_productos(createMv_productosInput: $createMv_productosInput) {
      pr_ID
      pr_sku
      pr_idEmpresa
      pr_idw
      pr_gtin
      pr_imagen
      pr_name
      pr_description
      pr_Nota_Venta
      pr_slug
      pr_perma_link
      pr_peso
      pr_largo
      pr_ancho
      pr_altura
      pr_CuentaContable
      pr_tipo
      pr_estatus
      pr_precio_descuento
      pr_precio_regular
      pr_precio
      pr_date_on_sale_from
      pr_date_on_sale_to
      pr_on_sale
      pr_shipping_required
      pr_shipping_taxable
      pr_shipping_class
      pr_shipping_class_id
      pr_stock_status
      pr_links
      pr_tax_status
      pr_tax_class
      pr_manage_stock
      pr_TasaEntrada
      pr_TasaSalida
      pr_Bloqueado
      pr_ClaveProdServ
      pr_ClaveUnidad
      pr_Unidad
      pr_Cantidad_Embalaje
      pr_Factor
      pr_Multi_Divisor
      pr_Codigo_alterno
      pr_Atributo
      delet
      createdAt
      updatedAt
    }
  }
`;
export const createMv_relacion_categoria_producto = /* GraphQL */ `
  mutation CreateMv_relacion_categoria_producto(
    $createMv_relacion_categoria_productoInput: CreateMv_relacion_categoria_productoInput!
  ) {
    createMv_relacion_categoria_producto(
      createMv_relacion_categoria_productoInput: $createMv_relacion_categoria_productoInput
    ) {
      CategoryId
      CodProducto
    }
  }
`;
export const createMv_relacion_etiquetas_producto = /* GraphQL */ `
  mutation CreateMv_relacion_etiquetas_producto(
    $createMv_relacion_etiquetas_productoInput: CreateMv_relacion_etiquetas_productoInput!
  ) {
    createMv_relacion_etiquetas_producto(
      createMv_relacion_etiquetas_productoInput: $createMv_relacion_etiquetas_productoInput
    ) {
      CodProducto
      idEtiquetas
    }
  }
`;
export const deleteMv_tasas = /* GraphQL */ `
  mutation DeleteMv_tasas($ts_codTasa: String!) {
    deleteMv_tasas(ts_codTasa: $ts_codTasa) {
      ts_Empresa
      ts_codTasa
      ts_slugTasa
      ts_nomTasa
      ts_afStock
      ts_afFin
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_log = /* GraphQL */ `
  mutation DeleteMv_log($lg_uid: Int!) {
    deleteMv_log(lg_uid: $lg_uid) {
      lg_uid
      lg_nombre
      lg_actividad
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_agentes = /* GraphQL */ `
  mutation DeleteMv_agentes($ID: Int!) {
    deleteMv_agentes(ID: $ID) {
      agen_Apellido
      agen_Puesto
      agen_Uid
      ID
      agen_idEmpresa
      agen_nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_atributos = /* GraphQL */ `
  mutation DeleteMv_atributos($ID: Int!) {
    deleteMv_atributos(ID: $ID) {
      ID
      atr_idatributo
      atr_idempresa
      atr_idw
      atr_nombre
      atr_opcion
      atr_posicion
      atr_variacion
      atr_visible
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_categorias = /* GraphQL */ `
  mutation DeleteMv_categorias($cat_idCategoria: Int!) {
    deleteMv_categorias(cat_idCategoria: $cat_idCategoria) {
      cat_description
      ID
      cat_idCategoria
      cat_idEmpresa
      cat_image
      cat_links
      cat_name
      cat_parent
      cat_slug
      delet
      createdAt
      updatedAt
      idCategorias
    }
  }
`;
export const deleteMv_clientedet = /* GraphQL */ `
  mutation DeleteMv_clientedet($ID: Int!) {
    deleteMv_clientedet(ID: $ID) {
      cd_agente
      cd_categoria
      cd_condPago
      cd_idCLiente
      ID
      cd_idEmpresa
      cd_limCred
      cd_lp
      cd_plaCred
      cd_usoCfdi
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_clientes = /* GraphQL */ `
  mutation DeleteMv_clientes($cte_razonSocial: String!) {
    deleteMv_clientes(cte_razonSocial: $cte_razonSocial) {
      cte_Apellidos
      cte_Correo
      cte_Nombre
      ID
      cte_idEmpresa
      cte_idwoo
      cte_razonSocial
      cte_rfc
      cte_tel
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_contactos = /* GraphQL */ `
  mutation DeleteMv_contactos($con_idContacto: Int!) {
    deleteMv_contactos(con_idContacto: $con_idContacto) {
      con_Correo
      con_Curp
      con_apellido
      con_fechaNac
      con_idContacto
      con_idEmpresa
      con_idExterna
      con_nombre
      con_puesto
      con_rfc
      con_telefono
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_detallelpv = /* GraphQL */ `
  mutation DeleteMv_detallelpv($lpv_CodProducto: String!) {
    deleteMv_detallelpv(lpv_CodProducto: $lpv_CodProducto) {
      delet
      createdAt
      updatedAt
      lpv_CodProveedor
      ID
      lpv_CodProducto
      lpv_IdTab
      lpv_TipCalc
      lpv_codLp
      lpv_factor
      lpv_idEmpresa
      lpv_prcBase
      lpv_prcFin
    }
  }
`;
export const deleteMv_direccion = /* GraphQL */ `
  mutation DeleteMv_direccion($dir_tipoDir: String!) {
    deleteMv_direccion(dir_tipoDir: $dir_tipoDir) {
      delet
      createdAt
      updatedAt
      dir_CP
      dir_Pais
      dir_ciudad
      dir_correo
      dir_dirLine1
      dir_dirLine2
      dir_estado
      dir_idCliente
      ID
      dir_idEmpresa
      dir_tel
      dir_tipoDir
    }
  }
`;
export const deleteMv_estatus = /* GraphQL */ `
  mutation DeleteMv_estatus($ID: Int!) {
    deleteMv_estatus(ID: $ID) {
      delet
      createdAt
      updatedAt
      ID
      esta_descripcion
      esta_idEstatus
    }
  }
`;
export const deleteMv_etiquetas = /* GraphQL */ `
  mutation DeleteMv_etiquetas($et_idEtiqueta: Int!) {
    deleteMv_etiquetas(et_idEtiqueta: $et_idEtiqueta) {
      delet
      createdAt
      updatedAt
      et_count
      et_description
      et_id
      et_idEmpresa
      et_idEtiqueta
      et_links
      et_name
      et_self
      et_slug
    }
  }
`;
export const deleteMv_listaprecios = /* GraphQL */ `
  mutation DeleteMv_listaprecios($lp_codLp: String!) {
    deleteMv_listaprecios(lp_codLp: $lp_codLp) {
      delet
      createdAt
      updatedAt
      lp_CodMoneda
      ID
      lp_codLp
      lp_nomdLp
    }
  }
`;
export const deleteMv_ordencontrol = /* GraphQL */ `
  mutation DeleteMv_ordencontrol($ctd_idordenes: Int!) {
    deleteMv_ordencontrol(ctd_idordenes: $ctd_idordenes) {
      ctd_NumRequisi
      ctd_OrderUrl
      ctd_clienteUrl
      ctd_codreg
      ID
      ctd_idordenes
      ctd_pedidoespecial
      ctd_precioxvolumen
      ctd_prjavisoentrega
      ctd_prjconfirmarpedido
      ctd_status
      ctd_tiempoentrega
      ctd_tiplib
      ctd_tpcarga
      ctd_valida
      ctd_vigenciacotizacion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_ordenes = /* GraphQL */ `
  mutation DeleteMv_ordenes($or_idorden: Int!) {
    deleteMv_ordenes(or_idorden: $or_idorden) {
      delet
      createdAt
      updatedAt
      ID
      or_agenteID
      or_clienteIP
      or_codMon
      or_descImp
      or_descTotal
      or_dirEnvioID
      or_dirFactID
      or_envImp
      or_envioTotal
      or_idCLiente
      or_idCondPago
      or_idEmpresa
      or_idListaP
      or_idordenExt
      or_mensaje
      or_ordenOrigen
      or_subtotal
      or_totalFinal
      or_totalImp
    }
  }
`;
export const deleteMv_ordenesdetalles = /* GraphQL */ `
  mutation DeleteMv_ordenesdetalles($ord_idEmpresa: Int!) {
    deleteMv_ordenesdetalles(ord_idEmpresa: $ord_idEmpresa) {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const deleteMv_productos = /* GraphQL */ `
  mutation DeleteMv_productos($pr_sku: String!) {
    deleteMv_productos(pr_sku: $pr_sku) {
      pr_ID
      pr_sku
      pr_idEmpresa
      pr_idw
      pr_gtin
      pr_imagen
      pr_name
      pr_description
      pr_Nota_Venta
      pr_slug
      pr_perma_link
      pr_peso
      pr_largo
      pr_ancho
      pr_altura
      pr_CuentaContable
      pr_tipo
      pr_estatus
      pr_precio_descuento
      pr_precio_regular
      pr_precio
      pr_date_on_sale_from
      pr_date_on_sale_to
      pr_on_sale
      pr_shipping_required
      pr_shipping_taxable
      pr_shipping_class
      pr_shipping_class_id
      pr_stock_status
      pr_links
      pr_tax_status
      pr_tax_class
      pr_manage_stock
      pr_TasaEntrada
      pr_TasaSalida
      pr_Bloqueado
      pr_ClaveProdServ
      pr_ClaveUnidad
      pr_Unidad
      pr_Cantidad_Embalaje
      pr_Factor
      pr_Multi_Divisor
      pr_Codigo_alterno
      pr_Atributo
      delet
      createdAt
      updatedAt
    }
  }
`;
export const deleteMv_relacion_categoria_producto = /* GraphQL */ `
  mutation DeleteMv_relacion_categoria_producto($CodProducto: String!) {
    deleteMv_relacion_categoria_producto(CodProducto: $CodProducto) {
      CategoryId
      CodProducto
    }
  }
`;
export const deleteMv_relacion_etiquetas_producto = /* GraphQL */ `
  mutation DeleteMv_relacion_etiquetas_producto($CodProducto: String!) {
    deleteMv_relacion_etiquetas_producto(CodProducto: $CodProducto) {
      CodProducto
      idEtiquetas
    }
  }
`;
export const deletemv_ordenesdetalles = /* GraphQL */ `
  mutation Deletemv_ordenesdetalles($ID: Int!) {
    deletemv_ordenesdetalles(ID: $ID) {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const updateMv_unidades = /* GraphQL */ `
  mutation UpdateMv_unidades($UpdateMv_unidadesInput: UpdateMv_unidadesInput!) {
    updateMv_unidades(UpdateMv_unidadesInput: $UpdateMv_unidadesInput) {
      unid_cod_unidad
      unid_nombre
      ID
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_tasas = /* GraphQL */ `
  mutation UpdateMv_tasas($updateMv_tasasInput: UpdateMv_tasasInput!) {
    updateMv_tasas(updateMv_tasasInput: $updateMv_tasasInput) {
      ts_Empresa
      ts_codTasa
      ts_slugTasa
      ts_nomTasa
      ts_afStock
      ts_afFin
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_log = /* GraphQL */ `
  mutation UpdateMv_log($updateMv_logInput: UpdateMv_logInput!) {
    updateMv_log(updateMv_logInput: $updateMv_logInput) {
      lg_uid
      lg_nombre
      lg_actividad
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_agentes = /* GraphQL */ `
  mutation UpdateMv_agentes($updateMv_agentesInput: UpdateMv_agentesInput!) {
    updateMv_agentes(updateMv_agentesInput: $updateMv_agentesInput) {
      agen_Apellido
      agen_Puesto
      agen_Uid
      ID
      agen_idEmpresa
      agen_nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_atributos = /* GraphQL */ `
  mutation UpdateMv_atributos(
    $updateMv_atributosInput: UpdateMv_atributosInput!
  ) {
    updateMv_atributos(updateMv_atributosInput: $updateMv_atributosInput) {
      ID
      atr_idatributo
      atr_idempresa
      atr_idw
      atr_nombre
      atr_opcion
      atr_posicion
      atr_variacion
      atr_visible
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_categorias = /* GraphQL */ `
  mutation UpdateMv_categorias(
    $updateMv_categoriasInput: UpdateMv_categoriasInput!
  ) {
    updateMv_categorias(updateMv_categoriasInput: $updateMv_categoriasInput) {
      cat_description
      ID
      cat_idCategoria
      cat_idEmpresa
      cat_image
      cat_links
      cat_name
      cat_parent
      cat_slug
      delet
      createdAt
      updatedAt
      idCategorias
    }
  }
`;
export const updateMv_clientedet = /* GraphQL */ `
  mutation UpdateMv_clientedet(
    $updateMv_clientedetInput: UpdateMv_clientedetInput!
  ) {
    updateMv_clientedet(updateMv_clientedetInput: $updateMv_clientedetInput) {
      cd_agente
      cd_categoria
      cd_condPago
      cd_idCLiente
      ID
      cd_idEmpresa
      cd_limCred
      cd_lp
      cd_plaCred
      cd_usoCfdi
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_clientes = /* GraphQL */ `
  mutation UpdateMv_clientes($updateMv_clientesInput: UpdateMv_clientesInput!) {
    updateMv_clientes(updateMv_clientesInput: $updateMv_clientesInput) {
      cte_Apellidos
      cte_Correo
      cte_Nombre
      ID
      cte_idEmpresa
      cte_idwoo
      cte_razonSocial
      cte_rfc
      cte_tel
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_contactos = /* GraphQL */ `
  mutation UpdateMv_contactos(
    $updateMv_contactosInput: UpdateMv_contactosInput!
  ) {
    updateMv_contactos(updateMv_contactosInput: $updateMv_contactosInput) {
      con_Correo
      con_Curp
      con_apellido
      con_fechaNac
      con_idContacto
      con_idEmpresa
      con_idExterna
      con_nombre
      con_puesto
      con_rfc
      con_telefono
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_detallelpv = /* GraphQL */ `
  mutation UpdateMv_detallelpv(
    $updateMv_detallelpvInput: UpdateMv_detallelpvInput!
  ) {
    updateMv_detallelpv(updateMv_detallelpvInput: $updateMv_detallelpvInput) {
      delet
      createdAt
      updatedAt
      lpv_CodProveedor
      ID
      lpv_CodProducto
      lpv_IdTab
      lpv_TipCalc
      lpv_codLp
      lpv_factor
      lpv_idEmpresa
      lpv_prcBase
      lpv_prcFin
    }
  }
`;
export const updateMv_direccion = /* GraphQL */ `
  mutation UpdateMv_direccion(
    $updateMv_direccionInput: UpdateMv_direccionInput!
  ) {
    updateMv_direccion(updateMv_direccionInput: $updateMv_direccionInput) {
      delet
      createdAt
      updatedAt
      dir_CP
      dir_Pais
      dir_ciudad
      dir_correo
      dir_dirLine1
      dir_dirLine2
      dir_estado
      dir_idCliente
      ID
      dir_idEmpresa
      dir_tel
      dir_tipoDir
    }
  }
`;
export const updateMv_estatus = /* GraphQL */ `
  mutation UpdateMv_estatus($updateMv_estatusInput: UpdateMv_estatusInput!) {
    updateMv_estatus(updateMv_estatusInput: $updateMv_estatusInput) {
      delet
      createdAt
      updatedAt
      ID
      esta_descripcion
      esta_idEstatus
    }
  }
`;
export const updateMv_etiquetas = /* GraphQL */ `
  mutation UpdateMv_etiquetas(
    $updateMv_etiquetasInput: UpdateMv_etiquetasInput!
  ) {
    updateMv_etiquetas(updateMv_etiquetasInput: $updateMv_etiquetasInput) {
      delet
      createdAt
      updatedAt
      et_count
      et_description
      et_id
      et_idEmpresa
      et_idEtiqueta
      et_links
      et_name
      et_self
      et_slug
    }
  }
`;
export const updateMv_listaprecios = /* GraphQL */ `
  mutation UpdateMv_listaprecios(
    $updateMv_listapreciosInput: UpdateMv_listapreciosInput!
  ) {
    updateMv_listaprecios(
      updateMv_listapreciosInput: $updateMv_listapreciosInput
    ) {
      delet
      createdAt
      updatedAt
      lp_CodMoneda
      ID
      lp_codLp
      lp_nomdLp
    }
  }
`;
export const updateMv_ordencontrol = /* GraphQL */ `
  mutation UpdateMv_ordencontrol(
    $updateMv_ordencontrolInput: UpdateMv_ordencontrolInput!
  ) {
    updateMv_ordencontrol(
      updateMv_ordencontrolInput: $updateMv_ordencontrolInput
    ) {
      ctd_NumRequisi
      ctd_OrderUrl
      ctd_clienteUrl
      ctd_codreg
      ID
      ctd_idordenes
      ctd_pedidoespecial
      ctd_precioxvolumen
      ctd_prjavisoentrega
      ctd_prjconfirmarpedido
      ctd_status
      ctd_tiempoentrega
      ctd_tiplib
      ctd_tpcarga
      ctd_valida
      ctd_vigenciacotizacion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_ordenes = /* GraphQL */ `
  mutation UpdateMv_ordenes($updateMv_ordenesInput: UpdateMv_ordenesInput!) {
    updateMv_ordenes(updateMv_ordenesInput: $updateMv_ordenesInput) {
      delet
      createdAt
      updatedAt
      ID
      or_agenteID
      or_clienteIP
      or_codMon
      or_descImp
      or_descTotal
      or_dirEnvioID
      or_dirFactID
      or_envImp
      or_envioTotal
      or_idCLiente
      or_idCondPago
      or_idEmpresa
      or_idListaP
      or_idordenExt
      or_mensaje
      or_ordenOrigen
      or_subtotal
      or_totalFinal
      or_totalImp
    }
  }
`;
export const updateMv_ordenesdetalles = /* GraphQL */ `
  mutation UpdateMv_ordenesdetalles(
    $updateMv_ordenesdetallesInput: UpdateMv_ordenesdetallesInput!
  ) {
    updateMv_ordenesdetalles(
      updateMv_ordenesdetallesInput: $updateMv_ordenesdetallesInput
    ) {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const updateMv_productos = /* GraphQL */ `
  mutation UpdateMv_productos(
    $updateMv_productosInput: UpdateMv_productosInput!
  ) {
    updateMv_productos(updateMv_productosInput: $updateMv_productosInput) {
      pr_ID
      pr_sku
      pr_idEmpresa
      pr_idw
      pr_gtin
      pr_imagen
      pr_name
      pr_description
      pr_Nota_Venta
      pr_slug
      pr_perma_link
      pr_peso
      pr_largo
      pr_ancho
      pr_altura
      pr_CuentaContable
      pr_tipo
      pr_estatus
      pr_precio_descuento
      pr_precio_regular
      pr_precio
      pr_date_on_sale_from
      pr_date_on_sale_to
      pr_on_sale
      pr_shipping_required
      pr_shipping_taxable
      pr_shipping_class
      pr_shipping_class_id
      pr_stock_status
      pr_links
      pr_tax_status
      pr_tax_class
      pr_manage_stock
      pr_TasaEntrada
      pr_TasaSalida
      pr_Bloqueado
      pr_ClaveProdServ
      pr_ClaveUnidad
      pr_Unidad
      pr_Cantidad_Embalaje
      pr_Factor
      pr_Multi_Divisor
      pr_Codigo_alterno
      pr_Atributo
      delet
      createdAt
      updatedAt
    }
  }
`;
export const updateMv_relacion_categoria_producto = /* GraphQL */ `
  mutation UpdateMv_relacion_categoria_producto(
    $updateMv_relacion_categoria_productoInput: UpdateMv_relacion_categoria_productoInput!
  ) {
    updateMv_relacion_categoria_producto(
      updateMv_relacion_categoria_productoInput: $updateMv_relacion_categoria_productoInput
    ) {
      CategoryId
      CodProducto
    }
  }
`;
export const updateMv_relacion_etiquetas_producto = /* GraphQL */ `
  mutation UpdateMv_relacion_etiquetas_producto(
    $updateMv_relacion_etiquetas_productoInput: UpdateMv_relacion_etiquetas_productoInput!
  ) {
    updateMv_relacion_etiquetas_producto(
      updateMv_relacion_etiquetas_productoInput: $updateMv_relacion_etiquetas_productoInput
    ) {
      CodProducto
      idEtiquetas
    }
  }
`;
