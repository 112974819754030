import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import {
  VALIDATE_USER,
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  validateUserSuccess,
  validateUserError,
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

import { adminRoot, currentUser } from '../../constants/defaultValues';
import { setCurrentUser, fetchCurrentUser } from '../../helpers/Utils';
import { removeDB } from '../../indexedDB';

const validateWithEmailCodeAsync = async (email, code) =>
  Auth.confirmSignUp(email, code)
    .then((user) => user)
    .catch((error) => error);

function* validateWithEmailCode({ payload }) {
  const { email, code } = payload.user;
  try {
    const validateUser = yield call(validateWithEmailCodeAsync, email, code);
    if (!validateUser?.message) {
      yield put(validateUserSuccess('Login is required.'));
    } else {
      yield put(validateUserError(validateUser.message));
    }
  } catch (error) {
    yield put(validateUserError(error.message));
  }
}

export function* watchValidateUser() {
  yield takeEvery(VALIDATE_USER, validateWithEmailCode);
}

const fetchCurrentUserAsync = async (uid) => fetchCurrentUser(uid);

const loginWithEmailPasswordAsync = async (email, password) =>
  Auth.signIn(email, password)
    .then((user) => {
      return {
        user: {
          uid: user.username,
        },
      };
    })
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser?.message) {
      let item;
      const fetchedCurrentUser = yield call(
        fetchCurrentUserAsync,
        loginUser.user.uid
      );
      if (fetchedCurrentUser) {
        item = {
          ...currentUser,
          uid: loginUser.user.uid,
          ...fetchedCurrentUser,
        };
      } else {
        item = { ...currentUser, uid: loginUser.user.uid };
      }
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error.message));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password, name) =>
  Auth.signUp({
    username: email,
    password,
    attributes: {
      name,
    },
  })
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password, name } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      name
    );
    if (!registerUser?.message) {
      yield put(registerUserSuccess());
      history.push('/user/login', { email });
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error.message));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const logoutAsync = async () =>
  Auth.signOut()
    .then((user) => user)
    .catch((error) => error);

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser(null);
  yield call(logoutAsync);
  yield call(removeDB);
  history.push(adminRoot);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const forgotPasswordAsync = async (email) =>
  Auth.forgotPassword(email)
    .then((user) => user)
    .catch((error) => error);

function* forgotPassword({ payload }) {
  const { email } = payload;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus?.message) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error.message));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const resetPasswordAsync = async (email, code, password) =>
  Auth.forgotPasswordSubmit(email, code, password)
    .then((user) => user)
    .catch((error) => error);

function* resetPassword({ payload }) {
  const { email, code, password } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      email,
      code,
      password
    );
    if (!resetPasswordStatus?.message) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error.message));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchValidateUser),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
