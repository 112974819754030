import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import search from "./search/reducer";
import clientes from "./clientes/reducer";
import articulos from "./articulos/reducer";
import presupuestos from "./presupuestos/reducer";
import sat from "./sat/reducer";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  search,
  clientes,
  articulos,
  presupuestos,
  sat,
});

export default reducers;
