const INIT_STATE = {
  loading: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "INIT":
      return { ...INIT_STATE };
    case "UPDATE_ARTICULOS":
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default reducer;
