import {
  VALIDATE_USER,
  VALIDATE_USER_SUCCESS,
  VALIDATE_USER_ERROR,
  UPDATE_USER,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "../actions";
import { getCurrentUser } from "../../helpers/Utils";

const INIT_STATE = {
  currentUser: getCurrentUser(),
  loading: false,
  error: "",
  forgotError: "",
  resetError: "",
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VALIDATE_USER:
      return { ...state, loading: true, error: "" };
    case VALIDATE_USER_SUCCESS:
      return { ...state, loading: false, error: action.payload.message };
    case VALIDATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    case UPDATE_USER:
      return { ...state, currentUser: action.payload };

    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };

    case FORGOT_PASSWORD:
      return { ...state, loading: true, forgotError: "" };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotError: action.payload.message,
        resetError: "",
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotError: action.payload.message,
        resetError: "",
      };

    case RESET_PASSWORD:
      return { ...state, loading: true, resetError: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetError: action.payload.message,
        forgotError: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        resetError: action.payload.message,
        forgotError: "",
      };

    case REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, error: "" };
    case REGISTER_USER_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    case LOGOUT_USER:
      return { ...state, currentUser: null, error: "" };
    default:
      return { ...state };
  }
};

export default reducer;
