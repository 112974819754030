// this is an auto generated file. This will be overwritten

export const getcurrentUser = /* GraphQL */ `
  query GetcurrentUser($uidUsuario: String!) {
    getcurrentUser(uidUsuario: $uidUsuario) {
      apellido
      idEmpresa
      logo
      nombre
      nombreComercial
      uidUsuario
    }
  }
`;
export const getPresupuesto = /* GraphQL */ `
  query GetPresupuesto($or_idorden: Int!) {
    getPresupuesto(or_idorden: $or_idorden) {
      or_idordenExt
      cte_Nombre
      cte_Apellidos
      or_ordenOrigen
      or_codMon
      or_totalFinal
      createdAt
      updatedAt
      delet
    }
  }
`;
export const getDetallePresupuesto = /* GraphQL */ `
  query GetDetallePresupuesto($ord_idwoo: Int!) {
    getDetallePresupuesto(ord_idwoo: $ord_idwoo) {
      pr_sku
      pr_name
      ord_cantProducto
      ord_precio_unitario
      ord_subtotal
      or_subtotal
      or_descTotal
      or_envioTotal
      or_totalImp
      or_totalFinal
    }
  }
`;
export const getAplicaciones = /* GraphQL */ `
  query GetAplicaciones($id: Int!) {
    getAplicaciones(id: $id) {
      delet
      descripcion
      id
      logo
      nombreApp
    }
  }
`;
export const getClientes = /* GraphQL */ `
  query GetClientes($id: Int!) {
    getClientes(id: $id) {
      apellido
      correo
      delet
      id
      nombre
      telefono
      uid
    }
  }
`;
export const getClientesByUID = /* GraphQL */ `
  query GetClientesByUID($uid: String!) {
    getClientesByUID(uid: $uid) {
      apellido
      correo
      delet
      id
      nombre
      telefono
      uid
    }
  }
`;
export const getEmpresa = /* GraphQL */ `
  query GetEmpresa($id: Int!) {
    getEmpresa(id: $id) {
      calle
      codigoPostal
      colonia
      correoElectronico
      delet
      estado
      estatus
      id
      idCliente
      localidad
      logo
      municipio
      noExt
      noInt
      nombreComercial
      pais
      regimenFiscal
      rfc
      telefono
      tipoPersona
    }
  }
`;
export const getEmpresaAplicacionUsuario = /* GraphQL */ `
  query GetEmpresaAplicacionUsuario($uidUsuario: String!) {
    getEmpresaAplicacionUsuario(uidUsuario: $uidUsuario) {
      activo
      delet
      dias
      fechaRenovacion
      id
      idApp
      idEmpresa
      idEmpresaApl
      idMenu
      idUsuario
      uidUsuario
    }
  }
`;
export const getEmpresaByCID = /* GraphQL */ `
  query GetEmpresaByCID($idCliente: Int!) {
    getEmpresaByCID(idCliente: $idCliente) {
      calle
      codigoPostal
      colonia
      correoElectronico
      delet
      estado
      estatus
      id
      idCliente
      localidad
      logo
      municipio
      noExt
      noInt
      nombreComercial
      pais
      regimenFiscal
      rfc
      telefono
      tipoPersona
    }
  }
`;
export const getPaquete = /* GraphQL */ `
  query GetPaquete($id: Int!) {
    getPaquete(id: $id) {
      delet
      id
      idAplicacion
      idPaquete
    }
  }
`;
export const getPlanes = /* GraphQL */ `
  query GetPlanes($id: Int!) {
    getPlanes(id: $id) {
      costoAnual
      costoMensual
      delet
      descripcion
      fechaFin
      id
      nombrePaquete
    }
  }
`;
export const getRenovaciones = /* GraphQL */ `
  query GetRenovaciones($id: Int!) {
    getRenovaciones(id: $id) {
      delet
      dias
      fecha
      fechaRenovacion
      id
      idApp
      idCliente
      idEmpresa
      idTransaccion
      idUsuario
    }
  }
`;
export const getTransacciones = /* GraphQL */ `
  query GetTransacciones($id: Int!) {
    getTransacciones(id: $id) {
      delet
      fecha
      id
      idEmpresa
      idPlan
      idProcesador
      monto
      numUsuarios
    }
  }
`;
export const getUsuarios = /* GraphQL */ `
  query GetUsuarios($id: Int!) {
    getUsuarios(id: $id) {
      apellido
      correo
      delet
      id
      idCliente
      nombre
      telefono
      uid
    }
  }
`;
export const getUsuariosByUID = /* GraphQL */ `
  query GetUsuariosByUID($uid: String!) {
    getUsuariosByUID(uid: $uid) {
      apellido
      correo
      delet
      id
      idCliente
      nombre
      telefono
      uid
    }
  }
`;
export const getcat_prodservsat = /* GraphQL */ `
  query Getcat_prodservsat($Prod_ClaveProdServ: String!) {
    getcat_prodservsat(Prod_ClaveProdServ: $Prod_ClaveProdServ) {
      Prod_ClaveProdServ
      Prod_Nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getcat_unidadsat = /* GraphQL */ `
  query Getcat_unidadsat($Unid_ClaveUnidad: String!) {
    getcat_unidadsat(Unid_ClaveUnidad: $Unid_ClaveUnidad) {
      Unid_ClaveUnidad
      Unid_Descripcion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getcat_unidadsat_search = /* GraphQL */ `
  query Getcat_unidadsat_search($texto: String!) {
    getcat_unidadsat_search(texto: $texto) {
      Unid_ClaveUnidad
      Unid_Descripcion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getcat_prodservsat_search = /* GraphQL */ `
  query Getcat_prodservsat_search($texto: String!) {
    getcat_prodservsat_search(texto: $texto) {
      Prod_ClaveProdServ
      Prod_Nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listPresupuestos = /* GraphQL */ `
  query ListPresupuestos {
    listPresupuestos {
      or_idordenExt
      cte_Nombre
      cte_Apellidos
      or_ordenOrigen
      or_codMon
      or_totalFinal
      createdAt
      updatedAt
      delet
    }
  }
`;
export const listAplicacioness = /* GraphQL */ `
  query ListAplicacioness {
    listAplicacioness {
      delet
      descripcion
      id
      logo
      nombreApp
    }
  }
`;
export const listClientess = /* GraphQL */ `
  query ListClientess {
    listClientess {
      apellido
      correo
      delet
      id
      nombre
      telefono
      uid
    }
  }
`;
export const listEmpresaAplicacionUsuarios = /* GraphQL */ `
  query ListEmpresaAplicacionUsuarios($uidUsuario: String!) {
    listEmpresaAplicacionUsuarios(uidUsuario: $uidUsuario) {
      activo
      appUrl
      dias
      fechaRenovacion
      idApp
      idEmpresa
      idMenu
      logo
      nombreApp
      nombreComercial
    }
  }
`;
export const listEmpresas = /* GraphQL */ `
  query ListEmpresas {
    listEmpresas {
      calle
      codigoPostal
      colonia
      correoElectronico
      delet
      estado
      estatus
      id
      idCliente
      localidad
      logo
      municipio
      noExt
      noInt
      nombreComercial
      pais
      regimenFiscal
      rfc
      telefono
      tipoPersona
    }
  }
`;
export const listPaquetes = /* GraphQL */ `
  query ListPaquetes {
    listPaquetes {
      delet
      id
      idAplicacion
      idPaquete
    }
  }
`;
export const listPlaness = /* GraphQL */ `
  query ListPlaness {
    listPlaness {
      costoAnual
      costoMensual
      delet
      descripcion
      fechaFin
      id
      nombrePaquete
    }
  }
`;
export const listRenovacioness = /* GraphQL */ `
  query ListRenovacioness {
    listRenovacioness {
      delet
      dias
      fecha
      fechaRenovacion
      id
      idApp
      idCliente
      idEmpresa
      idTransaccion
      idUsuario
    }
  }
`;
export const listTransaccioness = /* GraphQL */ `
  query ListTransaccioness {
    listTransaccioness {
      delet
      fecha
      id
      idEmpresa
      idPlan
      idProcesador
      monto
      numUsuarios
    }
  }
`;
export const listUsuarioss = /* GraphQL */ `
  query ListUsuarioss {
    listUsuarioss {
      apellido
      correo
      delet
      id
      idCliente
      nombre
      telefono
      uid
    }
  }
`;
export const listcat_prodservsats = /* GraphQL */ `
  query Listcat_prodservsats {
    listcat_prodservsats {
      Prod_ClaveProdServ
      Prod_Nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listcat_unidadsats = /* GraphQL */ `
  query Listcat_unidadsats {
    listcat_unidadsats {
      Unid_ClaveUnidad
      Unid_Descripcion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_unidadess = /* GraphQL */ `
  query ListMv_unidadess {
    listMv_unidadess {
      unid_cod_unidad
      unid_nombre
      ID
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_unidades = /* GraphQL */ `
  query GetMv_unidades($unid_cod_unidad: String!) {
    getMv_unidades(unid_cod_unidad: $unid_cod_unidad) {
      unid_cod_unidad
      unid_nombre
      ID
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_tasas = /* GraphQL */ `
  query GetMv_tasas($ts_codTasa: String!) {
    getMv_tasas(ts_codTasa: $ts_codTasa) {
      ts_Empresa
      ts_codTasa
      ts_slugTasa
      ts_nomTasa
      ts_afStock
      ts_afFin
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_log = /* GraphQL */ `
  query GetMv_log($lg_uid: Int!) {
    getMv_log(lg_uid: $lg_uid) {
      lg_uid
      lg_nombre
      lg_actividad
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_agentes = /* GraphQL */ `
  query GetMv_agentes($ID: Int!) {
    getMv_agentes(ID: $ID) {
      agen_Apellido
      agen_Puesto
      agen_Uid
      ID
      agen_idEmpresa
      agen_nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_atributos = /* GraphQL */ `
  query GetMv_atributos($ID: Int!) {
    getMv_atributos(ID: $ID) {
      ID
      atr_idatributo
      atr_idempresa
      atr_idw
      atr_nombre
      atr_opcion
      atr_posicion
      atr_variacion
      atr_visible
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_categorias = /* GraphQL */ `
  query GetMv_categorias($cat_idCategoria: Int!) {
    getMv_categorias(cat_idCategoria: $cat_idCategoria) {
      cat_description
      ID
      cat_idCategoria
      cat_idEmpresa
      cat_image
      cat_links
      cat_name
      cat_parent
      cat_slug
      delet
      createdAt
      updatedAt
      idCategorias
    }
  }
`;
export const getMv_clientedet = /* GraphQL */ `
  query GetMv_clientedet($ID: Int!) {
    getMv_clientedet(ID: $ID) {
      cd_agente
      cd_categoria
      cd_condPago
      cd_idCLiente
      ID
      cd_idEmpresa
      cd_limCred
      cd_lp
      cd_plaCred
      cd_usoCfdi
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_clientes = /* GraphQL */ `
  query GetMv_clientes($cte_razonSocial: String!) {
    getMv_clientes(cte_razonSocial: $cte_razonSocial) {
      cte_Apellidos
      cte_Correo
      cte_Nombre
      ID
      cte_idEmpresa
      cte_idwoo
      cte_razonSocial
      cte_rfc
      cte_tel
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_contactos = /* GraphQL */ `
  query GetMv_contactos($con_idContacto: Int!) {
    getMv_contactos(con_idContacto: $con_idContacto) {
      con_Correo
      con_Curp
      con_apellido
      con_fechaNac
      con_idContacto
      con_idEmpresa
      con_idExterna
      con_nombre
      con_puesto
      con_rfc
      con_telefono
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_detallelpvs = /* GraphQL */ `
  query GetMv_detallelpvs($lpv_CodProducto: String!) {
    getMv_detallelpvs(lpv_CodProducto: $lpv_CodProducto) {
      delet
      createdAt
      updatedAt
      lpv_CodProveedor
      ID
      lpv_CodProducto
      lpv_IdTab
      lpv_TipCalc
      lpv_codLp
      lpv_factor
      lpv_idEmpresa
      lpv_prcBase
      lpv_prcFin
    }
  }
`;
export const getMv_direccion = /* GraphQL */ `
  query GetMv_direccion($dir_tipoDir: String!) {
    getMv_direccion(dir_tipoDir: $dir_tipoDir) {
      delet
      createdAt
      updatedAt
      dir_CP
      dir_Pais
      dir_ciudad
      dir_correo
      dir_dirLine1
      dir_dirLine2
      dir_estado
      dir_idCliente
      ID
      dir_idEmpresa
      dir_tel
      dir_tipoDir
    }
  }
`;
export const getMv_estatus = /* GraphQL */ `
  query GetMv_estatus($ID: Int!) {
    getMv_estatus(ID: $ID) {
      delet
      createdAt
      updatedAt
      ID
      esta_descripcion
      esta_idEstatus
    }
  }
`;
export const getMv_etiquetas = /* GraphQL */ `
  query GetMv_etiquetas($et_idEtiqueta: Int!) {
    getMv_etiquetas(et_idEtiqueta: $et_idEtiqueta) {
      delet
      createdAt
      updatedAt
      et_count
      et_description
      et_id
      et_idEmpresa
      et_idEtiqueta
      et_links
      et_name
      et_self
      et_slug
    }
  }
`;
export const getMv_listaprecios = /* GraphQL */ `
  query GetMv_listaprecios($lp_codLp: String!) {
    getMv_listaprecios(lp_codLp: $lp_codLp) {
      delet
      createdAt
      updatedAt
      lp_CodMoneda
      ID
      lp_codLp
      lp_nomdLp
    }
  }
`;
export const getMv_ordencontrol = /* GraphQL */ `
  query GetMv_ordencontrol($ctd_idordenes: Int!) {
    getMv_ordencontrol(ctd_idordenes: $ctd_idordenes) {
      ctd_NumRequisi
      ctd_OrderUrl
      ctd_clienteUrl
      ctd_codreg
      ID
      ctd_idordenes
      ctd_pedidoespecial
      ctd_precioxvolumen
      ctd_prjavisoentrega
      ctd_prjconfirmarpedido
      ctd_status
      ctd_tiempoentrega
      ctd_tiplib
      ctd_tpcarga
      ctd_valida
      ctd_vigenciacotizacion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_ordenes = /* GraphQL */ `
  query GetMv_ordenes($or_idorden: Int!) {
    getMv_ordenes(or_idorden: $or_idorden) {
      delet
      createdAt
      updatedAt
      ID
      or_agenteID
      or_clienteIP
      or_codMon
      or_descImp
      or_descTotal
      or_dirEnvioID
      or_dirFactID
      or_envImp
      or_envioTotal
      or_idCLiente
      or_idCondPago
      or_idEmpresa
      or_idListaP
      or_idordenExt
      or_mensaje
      or_ordenOrigen
      or_subtotal
      or_totalFinal
      or_totalImp
    }
  }
`;
export const getMv_ordenesdetalles = /* GraphQL */ `
  query GetMv_ordenesdetalles($ord_idEmpresa: Int!) {
    getMv_ordenesdetalles(ord_idEmpresa: $ord_idEmpresa) {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const getMv_productos = /* GraphQL */ `
  query GetMv_productos($pr_sku: String!) {
    getMv_productos(pr_sku: $pr_sku) {
      pr_ID
      pr_sku
      pr_idEmpresa
      pr_idw
      pr_gtin
      pr_imagen
      pr_name
      pr_description
      pr_Nota_Venta
      pr_slug
      pr_perma_link
      pr_peso
      pr_largo
      pr_ancho
      pr_altura
      pr_CuentaContable
      pr_tipo
      pr_estatus
      pr_precio_descuento
      pr_precio_regular
      pr_precio
      pr_date_on_sale_from
      pr_date_on_sale_to
      pr_on_sale
      pr_shipping_required
      pr_shipping_taxable
      pr_shipping_class
      pr_shipping_class_id
      pr_stock_status
      pr_links
      pr_tax_status
      pr_tax_class
      pr_manage_stock
      pr_TasaEntrada
      pr_TasaSalida
      pr_Bloqueado
      pr_ClaveProdServ
      pr_ClaveUnidad
      pr_Unidad
      pr_Cantidad_Embalaje
      pr_Factor
      pr_Multi_Divisor
      pr_Codigo_alterno
      pr_Atributo
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_productos_search = /* GraphQL */ `
  query GetMv_productos_search($texto: String!) {
    getMv_productos_search(texto: $texto) {
      pr_ID
      pr_sku
      pr_idEmpresa
      pr_idw
      pr_gtin
      pr_imagen
      pr_name
      pr_description
      pr_Nota_Venta
      pr_slug
      pr_perma_link
      pr_peso
      pr_largo
      pr_ancho
      pr_altura
      pr_CuentaContable
      pr_tipo
      pr_estatus
      pr_precio_descuento
      pr_precio_regular
      pr_precio
      pr_date_on_sale_from
      pr_date_on_sale_to
      pr_on_sale
      pr_shipping_required
      pr_shipping_taxable
      pr_shipping_class
      pr_shipping_class_id
      pr_stock_status
      pr_links
      pr_tax_status
      pr_tax_class
      pr_manage_stock
      pr_TasaEntrada
      pr_TasaSalida
      pr_Bloqueado
      pr_ClaveProdServ
      pr_ClaveUnidad
      pr_Unidad
      pr_Cantidad_Embalaje
      pr_Factor
      pr_Multi_Divisor
      pr_Codigo_alterno
      pr_Atributo
      delet
      createdAt
      updatedAt
    }
  }
`;
export const getMv_relacion_categoria_producto = /* GraphQL */ `
  query GetMv_relacion_categoria_producto($CodProducto: String!) {
    getMv_relacion_categoria_producto(CodProducto: $CodProducto) {
      CategoryId
      CodProducto
    }
  }
`;
export const getMv_relacion_etiquetas_producto = /* GraphQL */ `
  query GetMv_relacion_etiquetas_producto($CodProducto: String!) {
    getMv_relacion_etiquetas_producto(CodProducto: $CodProducto) {
      CodProducto
      idEtiquetas
    }
  }
`;
export const getmv_ordenesdetalles = /* GraphQL */ `
  query Getmv_ordenesdetalles($ID: Int!) {
    getmv_ordenesdetalles(ID: $ID) {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const listMv_api = /* GraphQL */ `
  query ListMv_api($idEmpresa: Int!) {
    listMv_api(idEmpresa: $idEmpresa) {
      api_id
      api_idEmpresa
      api_url
      api_consumerKey
      api_consumerSecret
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_agentess = /* GraphQL */ `
  query ListMv_agentess {
    listMv_agentess {
      agen_Apellido
      agen_Puesto
      agen_Uid
      ID
      agen_idEmpresa
      agen_nombre
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_atributoss = /* GraphQL */ `
  query ListMv_atributoss {
    listMv_atributoss {
      ID
      atr_idatributo
      atr_idempresa
      atr_idw
      atr_nombre
      atr_opcion
      atr_posicion
      atr_variacion
      atr_visible
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_categoriass = /* GraphQL */ `
  query ListMv_categoriass {
    listMv_categoriass {
      cat_description
      ID
      cat_idCategoria
      cat_idEmpresa
      cat_image
      cat_links
      cat_name
      cat_parent
      cat_slug
      delet
      createdAt
      updatedAt
      idCategorias
    }
  }
`;
export const listMv_clientedets = /* GraphQL */ `
  query ListMv_clientedets {
    listMv_clientedets {
      cd_agente
      cd_categoria
      cd_condPago
      cd_idCLiente
      ID
      cd_idEmpresa
      cd_limCred
      cd_lp
      cd_plaCred
      cd_usoCfdi
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_clientess = /* GraphQL */ `
  query ListMv_clientess {
    listMv_clientess {
      cte_Apellidos
      cte_Correo
      cte_Nombre
      ID
      cte_idEmpresa
      cte_idwoo
      cte_razonSocial
      cte_rfc
      cte_tel
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_contactoss = /* GraphQL */ `
  query ListMv_contactoss {
    listMv_contactoss {
      con_Correo
      con_Curp
      con_apellido
      con_fechaNac
      con_idContacto
      con_idEmpresa
      con_idExterna
      con_nombre
      con_puesto
      con_rfc
      con_telefono
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_detallelpvs = /* GraphQL */ `
  query ListMv_detallelpvs {
    listMv_detallelpvs {
      delet
      createdAt
      updatedAt
      lpv_CodProveedor
      ID
      lpv_CodProducto
      lpv_IdTab
      lpv_TipCalc
      lpv_codLp
      lpv_factor
      lpv_idEmpresa
      lpv_prcBase
      lpv_prcFin
    }
  }
`;
export const listMv_direccions = /* GraphQL */ `
  query ListMv_direccions($dir_idCliente: Int!) {
    listMv_direccions(dir_idCliente: $dir_idCliente) {
      delet
      createdAt
      updatedAt
      dir_CP
      dir_Pais
      dir_ciudad
      dir_correo
      dir_dirLine1
      dir_dirLine2
      dir_estado
      dir_idCliente
      ID
      dir_idEmpresa
      dir_tel
      dir_tipoDir
    }
  }
`;
export const listMv_estatuss = /* GraphQL */ `
  query ListMv_estatuss {
    listMv_estatuss {
      delet
      createdAt
      updatedAt
      ID
      esta_descripcion
      esta_idEstatus
    }
  }
`;
export const listMv_etiquetass = /* GraphQL */ `
  query ListMv_etiquetass {
    listMv_etiquetass {
      delet
      createdAt
      updatedAt
      et_count
      et_description
      et_id
      et_idEmpresa
      et_idEtiqueta
      et_links
      et_name
      et_self
      et_slug
    }
  }
`;
export const listMv_listaprecioss = /* GraphQL */ `
  query ListMv_listaprecioss {
    listMv_listaprecioss {
      delet
      createdAt
      updatedAt
      lp_CodMoneda
      ID
      lp_codLp
      lp_nomdLp
    }
  }
`;
export const listMv_ordencontrols = /* GraphQL */ `
  query ListMv_ordencontrols {
    listMv_ordencontrols {
      ctd_NumRequisi
      ctd_OrderUrl
      ctd_clienteUrl
      ctd_codreg
      ID
      ctd_idordenes
      ctd_pedidoespecial
      ctd_precioxvolumen
      ctd_prjavisoentrega
      ctd_prjconfirmarpedido
      ctd_status
      ctd_tiempoentrega
      ctd_tiplib
      ctd_tpcarga
      ctd_valida
      ctd_vigenciacotizacion
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_ordenesdetalless = /* GraphQL */ `
  query ListMv_ordenesdetalless {
    listMv_ordenesdetalless {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const listMv_ordeness = /* GraphQL */ `
  query ListMv_ordeness {
    listMv_ordeness {
      delet
      createdAt
      updatedAt
      ID
      or_agenteID
      or_clienteIP
      or_codMon
      or_descImp
      or_descTotal
      or_dirEnvioID
      or_dirFactID
      or_envImp
      or_envioTotal
      or_idCLiente
      or_idCondPago
      or_idEmpresa
      or_idListaP
      or_idordenExt
      or_mensaje
      or_ordenOrigen
      or_subtotal
      or_totalFinal
      or_totalImp
    }
  }
`;
export const listMv_productos = /* GraphQL */ `
  query ListMv_productos($idEmpresa: Int!) {
    listMv_productos(idEmpresa: $idEmpresa) {
      pr_ID
      pr_sku
      pr_idEmpresa
      pr_idw
      pr_gtin
      pr_imagen
      pr_name
      pr_description
      pr_Nota_Venta
      pr_slug
      pr_perma_link
      pr_peso
      pr_largo
      pr_ancho
      pr_altura
      pr_CuentaContable
      pr_tipo
      pr_estatus
      pr_precio_descuento
      pr_precio_regular
      pr_precio
      pr_date_on_sale_from
      pr_date_on_sale_to
      pr_on_sale
      pr_shipping_required
      pr_shipping_taxable
      pr_shipping_class
      pr_shipping_class_id
      pr_stock_status
      pr_links
      pr_tax_status
      pr_tax_class
      pr_manage_stock
      pr_TasaEntrada
      pr_TasaSalida
      pr_Bloqueado
      pr_ClaveProdServ
      pr_ClaveUnidad
      pr_Unidad
      pr_Cantidad_Embalaje
      pr_Factor
      pr_Multi_Divisor
      pr_Codigo_alterno
      pr_Atributo
      delet
      createdAt
      updatedAt
    }
  }
`;
export const listMv_relacion_categoria_productos = /* GraphQL */ `
  query ListMv_relacion_categoria_productos {
    listMv_relacion_categoria_productos {
      CategoryId
      CodProducto
    }
  }
`;
export const listMv_relacion_etiquetas_productos = /* GraphQL */ `
  query ListMv_relacion_etiquetas_productos {
    listMv_relacion_etiquetas_productos {
      CodProducto
      idEtiquetas
    }
  }
`;
export const listmv_ordenesdetalless = /* GraphQL */ `
  query Listmv_ordenesdetalless {
    listmv_ordenesdetalless {
      delet
      createdAt
      updatedAt
      ord_cantDIsp
      ord_cantProducto
      ord_desc
      ord_idEmpresa
      ord_idProducto
      ord_idTes
      ord_idUnidad
      ord_idWoo
      ID
      ord_impSubtotal
      ord_numItem
      ord_numPV
      ord_precio
      ord_subdesc
      ord_subtotal
      ord_total
    }
  }
`;
export const searchMV_productos = /* GraphQL */ `
  query SearchMV_productos($idEmpresa: Int!, $search: String!) {
    searchMV_productos(idEmpresa: $idEmpresa, search: $search) {
      pr_ID
      pr_sku
      pr_idEmpresa
      pr_idw
      pr_gtin
      pr_imagen
      pr_name
      pr_description
      pr_Nota_Venta
      pr_slug
      pr_perma_link
      pr_peso
      pr_largo
      pr_ancho
      pr_altura
      pr_CuentaContable
      pr_tipo
      pr_estatus
      pr_precio_descuento
      pr_precio_regular
      pr_precio
      pr_date_on_sale_from
      pr_date_on_sale_to
      pr_on_sale
      pr_shipping_required
      pr_shipping_taxable
      pr_shipping_class
      pr_shipping_class_id
      pr_stock_status
      pr_links
      pr_tax_status
      pr_tax_class
      pr_manage_stock
      pr_TasaEntrada
      pr_TasaSalida
      pr_Bloqueado
      pr_ClaveProdServ
      pr_ClaveUnidad
      pr_Unidad
      pr_Cantidad_Embalaje
      pr_Factor
      pr_Multi_Divisor
      pr_Codigo_alterno
      pr_Atributo
      delet
      createdAt
      updatedAt
    }
  }
`;
