export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
];

// Cambiar idApp, nombreApp y appUrl por los valores de la app correspondiente
export const currentAplicacion = {
  idApp: 2,
  nombreApp: 'MiNube',
  appUrl: 'https://minube.realvirtual.com.mx/user/login',
};

export const defaultEmpresa = {
  idEmpresa: 0,
  logo: '/assets/logos/mobile.svg',
  nombreComercial: 'default',
};

export const defaultAplicacion = {
  idApp: 0,
  nombreApp: 'Panel',
  appUrl: 'https://sentinela.realvirtual.com.mx/user/login',
};

export const defaultAplicaciones = (apps = []) => {
  const seen = new Set();
  const aplicaciones = [
    { ...currentAplicacion },
    { ...defaultAplicacion },
    ...apps,
  ].filter((aplicacion) => {
    const duplicate = seen.has(aplicacion.idApp);
    seen.add(aplicacion.idApp);
    return !duplicate;
  });
  return aplicaciones;
};

export const defaultEmpresasAplicaciones = (apps = []) => {
  return [
    {
      ...defaultEmpresa,
      aplicaciones: defaultAplicaciones(apps),
    },
  ];
};

export const currentUser = {
  ...defaultEmpresa,
  ...currentAplicacion,
  idCliente: null,
  role: UserRole.Admin,
  nombre: '',
  apellido: '',
  uid: null,
  uidUsuario: null,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
