import { API } from "aws-amplify";
import moment from "moment";

import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  currentUser,
} from "../constants/defaultValues";
import {
  getcurrentUser as apiFetchCurrentUser,
  getEmpresaAplicacionUsuario as apiFetchEmpresaAplicacionUsuario,
  listEmpresaAplicacionUsuarios as apiListEmpresaAplicacionUsuario,
  getClientesByUID as apiFetchClientesByUID,
  getUsuariosByUID as apiFetchUsuariosByUID,
  getEmpresaByCID as apiFetchEmpresaByCID,
} from "../graphql/queries";
import {
  createClientes,
  updateClientes,
  createUsuarios,
  updateUsuarios,
  createEmpresa,
  updateEmpresa,
  createEmpresaAplicacionUsuario,
} from "../graphql/mutations";
import {
  listMv_direccions as apiListDirecciones,
  listcat_unidadsats as apilistcat_unidadsats,
  listcat_prodservsats as apilistcat_prodservsats,
  listMv_clientess as apiListClientes,
  listPresupuestos as apiListPresupuestos,
  getDetallePresupuesto as apiGetDetallePresupuesto,
  listMv_productos as apiListProductos,
} from "../graphql/queries";
import { updateMv_productos } from "../graphql/mutations";

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem("direction")) {
      const localValue = localStorage.getItem("direction");
      if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  try {
    localStorage.setItem("direction", direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const getCurrentMainColor = () => {
  return getCurrentColor().split(".")[1];
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = "rounded";
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentRadius -> error",
      error
    );
    currentRadius = "rounded";
  }
  return currentRadius;
};

export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentRadius -> error",
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem("currentLanguage") &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem("currentLanguage")
      ).length > 0
        ? localStorage.getItem("currentLanguage")
        : defaultLocale;
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentLanguage -> error",
      error
    );
    language = defaultLocale;
  }
  return language;
};

export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem("currentLanguage", locale);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentLanguage -> error",
      error
    );
  }
};

export const fetchEmpresaAplicacionUsuario = (uidUsuario) => {
  return API.graphql({
    query: apiFetchEmpresaAplicacionUsuario,
    variables: { uidUsuario },
  })
    .then((gqlRes) => {
      return gqlRes.data.getEmpresaAplicacionUsuario;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const fetchCurrentUser = (uidUsuario) => {
  return API.graphql({
    query: apiFetchCurrentUser,
    variables: { uidUsuario },
  })
    .then((gqlRes) => {
      return gqlRes.data.getcurrentUser;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const fetchClientesByUID = (uid) => {
  return API.graphql({
    query: apiFetchClientesByUID,
    variables: { uid },
  })
    .then((gqlRes) => {
      return gqlRes.data.getClientesByUID;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const fetchUsuariosByUID = (uid) => {
  return API.graphql({
    query: apiFetchUsuariosByUID,
    variables: { uid },
  })
    .then((gqlRes) => {
      return gqlRes.data.getUsuariosByUID;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const updateUsuario = (usuario) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const prevUsuario = await fetchUsuariosByUID(usuario.uid);
        if (prevUsuario) {
          const updateUsuariosInput = {
            id: prevUsuario.id,
            uid: prevUsuario.uid,
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            correo: usuario.correo,
            telefono: usuario.telefono,
            idCliente: prevUsuario.idCliente,
            delet: "0",
          };
          const updateUsuariosResponse = await API.graphql({
            query: updateUsuarios,
            variables: { updateUsuariosInput },
          });
          if (updateUsuariosResponse?.data?.updateUsuarios) {
            resolve(updateUsuariosResponse.data.updateUsuarios);
          } else {
            reject(updateUsuariosResponse);
          }
        } else {
          reject(prevUsuario);
        }
      } catch (error) {
        reject(error);
      }
    })();
  });
};

export const putUsuario = (user) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        let cliente = await fetchClientesByUID(user.uid);
        if (cliente) {
          const updateClientesInput = {
            id: cliente.id,
            uid: user.uid,
            nombre: user.nombre,
            apellido: user.apellido,
            correo: user.correo,
            telefono: user.telefono,
            delet: "0",
          };
          const updateClientesResponse = await API.graphql({
            query: updateClientes,
            variables: { updateClientesInput },
          });
          if (updateClientesResponse?.data?.updateClientes) {
            cliente = { ...updateClientesResponse.data.updateClientes };
          } else {
            reject(updateClientesResponse);
          }
        } else {
          const createClientesInput = {
            uid: user.uid,
            nombre: user.nombre,
            apellido: user.apellido,
            correo: user.correo,
            telefono: user.telefono,
            delet: "0",
          };
          const createClientesResponse = await API.graphql({
            query: createClientes,
            variables: { createClientesInput },
          });
          if (createClientesResponse?.data?.createClientes) {
            cliente = { ...createClientesResponse.data.createClientes };
          } else {
            reject(createClientesResponse);
          }
        }
        let usuario = await fetchUsuariosByUID(user.uid);
        if (usuario) {
          const updateUsuariosInput = {
            id: usuario.id,
            uid: user.uid,
            nombre: user.nombre,
            apellido: user.apellido,
            correo: user.correo,
            telefono: user.telefono,
            idCliente: cliente.id,
            delet: "0",
          };
          const updateUsuariosResponse = await API.graphql({
            query: updateUsuarios,
            variables: { updateUsuariosInput },
          });
          if (updateUsuariosResponse?.data?.updateUsuarios) {
            usuario = { ...updateUsuariosResponse.data.updateUsuarios };
          } else {
            reject(updateUsuariosResponse);
          }
        } else {
          const createUsuariosInput = {
            uid: user.uid,
            nombre: user.nombre,
            apellido: user.apellido,
            correo: user.correo,
            telefono: user.telefono,
            idCliente: cliente.id,
            delet: "0",
          };
          const createUsuariosResponse = await API.graphql({
            query: createUsuarios,
            variables: { createUsuariosInput },
          });
          if (createUsuariosResponse?.data?.createUsuarios) {
            usuario = { ...createUsuariosResponse.data.createUsuarios };
          } else {
            reject(createUsuariosResponse);
          }
        }
        resolve({ ...user, ...cliente, ...usuario });
      } catch (error) {
        reject(error);
      }
    })();
  });
};

export const fetchEmpresaByCID = (idCliente) => {
  return API.graphql({
    query: apiFetchEmpresaByCID,
    variables: { idCliente },
  })
    .then((gqlRes) => {
      return gqlRes.data.getEmpresaByCID;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const putEmpresa = (empresaInput, user) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        let empresa = await fetchEmpresaByCID(user.idCliente);
        if (empresa) {
          const updateEmpresaInput = {
            id: empresa.id,
            estatus: "1",
            rfc: empresaInput.rfc,
            tipoPersona: empresaInput.tipoPersona,
            nombreComercial: empresaInput.nombreComercial,
            calle: empresaInput.calle,
            noExt: empresaInput.noExt,
            noInt: empresaInput.noInt,
            colonia: empresaInput.colonia,
            localidad: empresaInput.localidad,
            estado: empresaInput.estado,
            municipio: empresaInput.municipio,
            pais: empresaInput.pais,
            codigoPostal: empresaInput.codigoPostal,
            telefono: empresaInput.telefono,
            correoElectronico: empresaInput.correoElectronico,
            logo: "",
            idCliente: user.idCliente,
            delet: "0",
          };
          const updateEmpresaResponse = await API.graphql({
            query: updateEmpresa,
            variables: { updateEmpresaInput },
          });
          if (updateEmpresaResponse?.data?.updateEmpresa) {
            empresa = { ...updateEmpresaResponse.data.updateEmpresa };
          } else {
            reject(updateEmpresaResponse);
          }
        } else {
          const createEmpresaInput = {
            estatus: "1",
            rfc: empresaInput.rfc,
            tipoPersona: empresaInput.tipoPersona,
            nombreComercial: empresaInput.nombreComercial,
            calle: empresaInput.calle,
            noExt: empresaInput.noExt,
            noInt: empresaInput.noInt,
            colonia: empresaInput.colonia,
            localidad: empresaInput.localidad,
            estado: empresaInput.estado,
            municipio: empresaInput.municipio,
            pais: empresaInput.pais,
            codigoPostal: empresaInput.codigoPostal,
            telefono: empresaInput.telefono,
            correoElectronico: empresaInput.correoElectronico,
            logo: "",
            idCliente: user.idCliente,
            delet: "0",
          };
          const createEmpresaResponse = await API.graphql({
            query: createEmpresa,
            variables: { createEmpresaInput },
          });
          if (createEmpresaResponse?.data?.createEmpresa) {
            empresa = { ...createEmpresaResponse.data.createEmpresa };
          } else {
            reject(createEmpresaResponse);
          }
        }
        const empresaAplicacionUsuario = await fetchEmpresaAplicacionUsuario(
          user.uid
        );
        if (!empresaAplicacionUsuario) {
          const createEmpresaAplicacionUsuarioInput = {
            idEmpresa: empresa.id,
            uidUsuario: user.uid,
            idUsuario: user.id,
            idApp: 0, // RVPanel
            idMenu: 0,
            dias: 0,
            fechaRenovacion: moment().format("YYYY-MM-DD"),
            activo: "1",
            delet: "0",
          };
          const createEmpresaAplicacionUsuarioResponse = await API.graphql({
            query: createEmpresaAplicacionUsuario,
            variables: { createEmpresaAplicacionUsuarioInput },
          });
          if (
            createEmpresaAplicacionUsuarioResponse?.data
              ?.createEmpresaAplicacionUsuario
          ) {
            empresa = {
              ...empresa,
              ...createEmpresaAplicacionUsuarioResponse.data
                .createEmpresaAplicacionUsuario,
            };
          }
        }
        resolve(empresa);
      } catch (error) {
        reject(error);
      }
    })();
  });
};

export const listEmpresasAplicaciones = (uidUsuario) => {
  return API.graphql({
    query: apiListEmpresaAplicacionUsuario,
    variables: { uidUsuario },
  })
    .then((gqlRes) => {
      const data = gqlRes.data.listEmpresaAplicacionUsuarios;
      let seen = new Set();
      const soloEmpresas = data.filter((empresa) => {
        const duplicate = seen.has(empresa.idEmpresa);
        seen.add(empresa.idEmpresa);
        return !duplicate;
      });
      seen = new Set();
      const aplicaciones = data.filter((aplicacion) => {
        const duplicate = seen.has(aplicacion.idApp);
        seen.add(aplicacion.idApp);
        return !duplicate;
      });
      const empresas = soloEmpresas.map((empresa) => {
        const apps = [];
        aplicaciones.forEach((aplicacion) => {
          if (empresa.idEmpresa === aplicacion.idEmpresa) {
            apps.push(aplicacion);
          }
        });
        return { ...empresa, aplicaciones: apps };
      });
      return {
        empresas,
        aplicaciones,
      };
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const listDirecciones = (dir_idCliente) => {
  return API.graphql({
    query: apiListDirecciones,
    variables: { dir_idCliente },
  })
    .then((gqlRes) => {
      const data = gqlRes.data.listMv_direccions;
      return data.map((item) => ({
        id: item.dir_idDireccion,
        calle: item.dir_dirLine1,
        numeroInterior: "",
        numeroExterior: "#",
        codigoPostal: item.dir_CP,
        colonia: "Colonia",
        ciudad: item.dir_ciudad,
        estado: item.dir_estado,
        pais: item.dir_Pais,
        referencia: item.dir_dirLine2,
        correo: item.dir_correo,
        telefono: item.dir_tel,
      }));
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const listClientes = () => {
  return API.graphql({
    query: apiListClientes,
  })
    .then((gqlRes) => {
      const data = gqlRes.data.listMv_clientess;
      return data.map((item) => ({
        id: item.cte_idCliente,
        nombreComercial: item.cte_razonSocial,
        datosFacturacion: item.cte_rfc,
        datosEnvio: item.cte_rfc,
        search: `${item.cte_razonSocial} - ${item.cte_rfc}`,
      }));
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getcat_unidadsat = () => {
  return API.graphql({
    query: apilistcat_unidadsats,
  })
    .then((gqlRes) => {
      return gqlRes.data.listcat_unidadsats;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getcat_prodservsat = () => {
  return API.graphql({
    query: apilistcat_prodservsats,
  })
    .then((gqlRes) => {
      return gqlRes.data.listcat_prodservsats;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getClientes = () => {
  return API.graphql({
    query: apiListClientes,
  })
    .then((gqlRes) => {
      return gqlRes.data.listMv_clientess;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const listPresupuestos = () => {
  return API.graphql({
    query: apiListPresupuestos,
  })
    .then((gqlRes) => {
      return gqlRes.data.listPresupuestos;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getDetallePresupuesto = (id) => {
  return API.graphql({
    query: apiGetDetallePresupuesto,
    variables: {
      ord_idwoo: id,
    },
  }).then((gqlRes) => {
    return gqlRes.data.getDetallePresupuesto
      .map((ord) => {
        const ord_unitario = ord.ord_subtotal / ord.ord_cantProducto;
        const or_subtotal = ord.or_subtotal + ord.or_descTotal;
        const ord_desc = ord.or_descTotal / or_subtotal;
        const ord_descuento = ord_unitario * ord.ord_cantProducto * ord_desc;

        return {
          ...ord,
          ord_unitario: ord_unitario.toFixed(2),
          ord_descuento: ord_descuento.toFixed(2),
          or_subtotal: or_subtotal.toFixed(2),
        };
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  });
};

export const listProductos = () => {
  return API.graphql({
    query: apiListProductos,
  })
    .then((gqlRes) => {
      let data = gqlRes.data.listMv_productos;
      data = data.map((item) => ({
        sku: item.pr_sku,
        descripcion: item.pr_name,
        precioUnitario: item.pr_sale_price || 0,
        search: `${item.pr_sku} - ${item.pr_name}`,
        productDetail: item,
      }));
      return data;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getProductos = () => {
  return API.graphql({
    query: apiListProductos,
  })
    .then((gqlRes) => {
      return gqlRes.data.listMv_productos;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const updateProducto = (productData) => {
  return API.graphql({
    query: updateMv_productos,
    variables: { updateMv_productosInput: productData },
  })
    .then((gqlRes) => {
      return gqlRes.data.updateMv_productos;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getCurrentEmpresasAplicaciones = () => {
  let empresasAplicaciones;
  try {
    empresasAplicaciones =
      JSON.parse(localStorage.getItem("empresasAplicaciones")) || null;
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentEmpresasAplicaciones -> error",
      error
    );
    empresasAplicaciones = null;
  }
  return empresasAplicaciones;
};

export const setCurrentEmpresasAplicaciones = (empresasAplicaciones) => {
  try {
    if (empresasAplicaciones) {
      localStorage.setItem(
        "empresasAplicaciones",
        JSON.stringify(empresasAplicaciones)
      );
    } else {
      localStorage.removeItem("empresasAplicaciones");
    }
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentEmpresasAplicaciones -> error",
      error
    );
  }
};

export const getCurrentUser = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem("currentUser")) || null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ ...currentUser, ...user })
      );
    } else {
      localStorage.removeItem("menu");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("empresasAplicaciones");
      localStorage.removeItem("offlineQueue");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};
