import Dexie from "dexie";

const db = new Dexie("NubeMegaservicio");
db.version(10).stores({
  clientes: "cte_idCLiente, cte_idwoo, cte_Nombre, cte_Apellidos, cte_Correo",
  articulos: "pr_id, pr_sku, pr_idw, pr_name",
  ordenes: "ord_id, or_id, or_idordenExt, or_idCLiente, createdAt",
  ClaveProdServ: "id, descripcion",
  ClaveUnidad: "id, nombre",
});

export const countItems = (table) => {
  return db[table].count();
};

export const clearItems = (table) => {
  return db[table].clear();
};

export const getItems = (table) => {
  return new Promise((resolve, reject) => {
    db[table].toArray().then(resolve).catch(reject);
  });
};

export const addItems = (items, table) => {
  return new Promise((resolve, reject) => {
    db[table].bulkAdd(items).then(resolve).catch(reject);
  });
};

export const replaceItems = (items, table) => {
  return new Promise((resolve, reject) => {
    if (countItems(table)) {
      clearItems(table)
        .then(() => {
          addItems(items, table).then(resolve).catch(reject);
        })
        .catch(reject);
    } else {
      addItems(items, table).then(resolve).catch(reject);
    }
  });
};

export const searchItems = (searchText, table, columns) => {
  return new Promise((resolve, reject) => {
    if (!countItems(table)) {
      resolve([]);
    }
    if (searchText) {
      const words = searchText.split(" ").filter(String);
      if (words.length) {
        const filteredCollection = db[table].filter((item) => {
          return columns.some((column) => {
            const coincidences = [];
            const itemColumn = item[column].toLowerCase();
            words.forEach((word) => {
              coincidences.push(itemColumn.includes(word.toLowerCase()));
            });
            return words.length === coincidences.filter(Boolean).length;
          });
        });
        filteredCollection.toArray().then(resolve).catch(reject);
      } else {
        getItems(table).then(resolve).catch(reject);
      }
    } else {
      getItems(table).then(resolve).catch(reject);
    }
  });
};

export const updateItem = (key, value, item, table) => {
  return new Promise((resolve, reject) => {
    db[table].where(key).equals(value).modify(item).then(resolve).catch(reject);
  });
};

export const updatingItems = (action, table) => {
  return db[table].hook("updating", action);
};

export const unsubscribeUpdatingItems = (listener, table) => {
  return db[table].hook("updating").unsubscribe(listener);
};

export const createDB = () => {
  return new Promise((resolve, reject) => {
    db.open().then(resolve).catch(reject);
  });
};

export const removeDB = () => {
  return new Promise((resolve, reject) => {
    db.delete().then(resolve).catch(reject);
  });
};

export default db;
