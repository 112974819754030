import { Amplify } from "aws-amplify";
import config from "./aws-exports";

import "./assets/css/vendor/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "rvui/dist/esm/styles/index.css";
import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
} from "./constants/defaultValues";
import { getCurrentColor, setCurrentColor } from "./helpers/Utils";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Amplify.configure(config);

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require("./AppRenderer");
  });
};

render();
serviceWorkerRegistration.register();
