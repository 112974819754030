const INIT_STATE = {
  loadingClaveProdServ: false,
  loadingClaveUnidad: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "INIT":
      return { ...INIT_STATE };
    case "UPDATE_SAT":
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default reducer;
