const INIT_STATE = {
  articulos: "",
  clientes: "",
  presupuestos: "",
  // ...
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "INIT":
      return { ...INIT_STATE };
    case "RESET":
      return { ...state, [action.payload]: INIT_STATE[action.payload] };
    case "ONCHANGE":
      return { ...state, [action.payload.search]: action.payload.text };
    default:
      return { ...state };
  }
};

export default reducer;
